<script>  
	import { onMount } from 'svelte';
    import { fade } from 'svelte/transition';
	import { inview } from 'svelte-inview';
	import Blog from './Blog.svelte';
	import Skills from './Skills.svelte';

	async function PostForm(form) {
		const data = new URLSearchParams(new FormData(form));
		const response = await fetch("/", {
    		method: "POST",
			body: data
		})
		return response
	}
	
	let message;
	function Submit(event) {
		let form = event.target.parentElement
		PostForm(form).then(res => res.text())
    		.then(response => {
        	if (response !== "true") {
				message.textContent = "Er ging iets mis"
			} else {
				message.textContent = "Succes"
			}
		})
	}
	
	function HideUnhide(event) {
		if (event.detail.inView == true) {
			event.target.style.opacity = 1
		} else if (event.detail.inView == false) {
			event.target.style.opacity = 0
		}
	}
	
	let current_tab = "Home"
	function TabChange(tab) {
		current_tab = tab.target.id
	}
	let visible = false
	onMount( () => {
		visible = true
  	})
</script>
<main>
	<div class = "menu">
		<div id="Home" on:keydown={TabChange} on:click={TabChange}>Home</div>
		<div id="Blog" on:keydown={TabChange} on:click={TabChange}>Blog</div>
	</div>
	<div class="wrapper">
		{#if current_tab == "Home"}
			  <h1>Hello</h1>
				{#if visible}
					<div transition:fade={{ delay: 250, duration: 300 }}>
						<figure>
							<img src="images/person.jpg" alt="Person" width="200px">
							<figcaption>My picture</figcaption>
						</figure>
						<div class="element hidden" use:inview
						on:inview_change={HideUnhide}>
							<h2>About me</h2>
							<p>	My name is Sander Diederik.</p>
							<p>I am a freelance programmer and IT consultant.</p>
							<p>My interest in computers started in the early nineties on my fathers 8088.
								I learned to type on that machine and saw my first examples of BASIC</p>
							<p>In the late nineties I started out as a Network/Systems administrator and as time went by learned many more aspects of IT management.</p>
							<p>Eventually starting my own business doing consultancy, my main focus today is programming and consultancy.</p>
							
						</div>
						<div class="element hidden" use:inview
						on:inview_change={HideUnhide}>
							<h2>What do I do?</h2>
							<p>Currently my main focus in designing and creating Web applications. This includes a simple website, but also a complex application, API or CLI tool for specific customer needs.</p>
							<p>My language of choice for these projects is often PHP, but I also employ Python and Javascript.</p>
							<p>When building API's I usually tend to gravitate towards either Python or C++ depending on the requirements.</p>
							<p>I have a history in Systems Administration and Engineering, and continue to uphold these skills</p>
							<p>I think that having a deep understanding of networking concepts and systems as well as the software that runs on them
								really helps me in being a better programmer and consultant.</p>
							<p>Knowledge of the challenges that System/Network administrator have to overcome enable me to write actually useful tools</p>
						</div>
						<div class="element hidden" use:inview
						on:inview_change={HideUnhide}>
							<h2>What is this site?</h2>
							<p>This is my personal website and blog.</p>
							<p>I will post things here that are currently in my interest:<br>
							Things like personal projects, interests etc. Or any interesting parts of professional projects</p>
							<p>It is also a way to introduce myself to any like minded individuals or interested parties</p>
							<p>Below you may find some of the things I know</p>
						</div>
						<div class="element hidden" use:inview
						on:inview_change={HideUnhide}>
							<h2>Some things I know</h2>
							<Skills />
						</div>
					</div>
					<div class="element hidden" use:inview
						on:inview_change={HideUnhide}>
						<b>If you would like to contact me for any reason, please fill in the form below.</b>
					</div>
					<div class="element hidden" use:inview
						on:inview_change={HideUnhide}>
						<h3>Contact: </h3>
						<form method="POST">
							<input type="hidden" name="contact_form">
							<input type="text" name="firstname" placeholder="First Name">
							<input type="text" name="lastname" placeholder="Name">
							<input type="text" name="email" placeholder="Email">
							<textarea name="message"></textarea>
							<input on:click|preventDefault={Submit} type="submit" value="Send">
						</form>
						<div bind:this={message}></div>
					</div>
				{/if}
		{/if}
		{#if current_tab == "Blog"}
			<h1>Blog</h1>
			{#if visible}
				<div>
					<Blog />
				</div>
			{/if}
		{/if}
	</div>
</main>

<style>
	.element {
		padding: 1em;
		background-color: rgba(255, 255, 200, 0.3);
		margin: 4em;
		border-radius: 5px;
		color: black;
		text-shadow: 1px 1px 1px gray;
	}
	.menu {
		display: flex;
		justify-content: center;
		position: fixed;
		width: 100%;
		background-color: rgba(132, 60, 60, 0.5);
		z-index: 99;
	}
	.menu > * {
		padding-right: 1em;
		font-size: x-large;
		width: 5em;
		/* backdrop-filter: blur(20px); */
	}
	.menu > *:hover {
		transition: color 1s;
		cursor: pointer;
		color: blue;
	}
	.wrapper {
		margin: auto;
		width: 66vw;
	}
	/* .form_wrapper {
		opacity: 0;
		transition: 1s opacity;
	} */
	:global(body) {
		background-image: url("images/background.jpg");
		padding: 0px !important;
		margin: 0px !important;
		height: 100vh;
		/* backdrop-filter: blur(1px); */
		background-repeat: no-repeat;
  		background-size: cover;
		background-attachment: fixed;
	}
	p {
		max-width: 66%;
		margin: auto;
		margin-bottom: 1em;
		text-align: left;
		/* text-shadow: 1px 1px 1px pink; */
	}
	main {
		text-align: center;
		padding: 1em;
		max-width: 240px;
		margin: 0 auto;
	}
	form {
		display: flex;
		flex-direction: column;
		width: 33vw;
		margin: auto;
	}

	h1 {
		color: #1500ff;
		text-transform: uppercase;
		font-size: 4em;
		font-weight: 100;
	}
	/* h3, h2 {
		text-align: left;
	} */
	.hidden {
		transition: opacity 1s;
		opacity: 0;
	}
	
	@media (min-width: 640px) {
		main {
			max-width: none;
		}
	}
</style>