<script>
    import { inview } from 'svelte-inview';
    import { onMount } from "svelte";
    let js = false
    let page_number = 0
    let page_count = false
	function LoadJson() {
		fetch("?page=" + page_number).
		then(response => response.text()).
		then(data => 
			js = JSON.parse(data)
		)
	}
    LoadJson()
    var d
    function FormatDate(date) {
        d = new Date(date * 1000)
        return d.toLocaleDateString("en-GB")
    }
    function SetPage(event) {
        event.preventDefault()
        page_number = event.target.value
        LoadJson()
    }
    function GetCount() {
        fetch("?count").then(response => response.text()).
        then(data => page_count = parseInt(data))
    }
    onMount(() => {
        GetCount()
      })

</script>
<div class="superwrapper">
    <div class="sidebar">
    {#if page_count}
        <select on:change={SetPage}>
            {#each Array(page_count) as cn, cnt}
                <option value="{cnt}" name="page_number">Page {cnt + 1}</option>
            {/each}
        </select>
    {/if}
    <ul>
        {#each Object.entries(js) as [key, value]}
            <li style="text-align: left;"><a href='#{value.Name}'>{value.Name}</a></li>
        {/each}
    </ul>
</div>
    <span class="mega_wrapper">
        {#if js}
            {#each Object.entries(js) as [key, value]}
            <div class="wrapper" id="{value.Name}">
                <!-- <span class="info">
                    <h3>{value.Name}</h3>
                    <p>Author: {value.Author}</p>
                    <p>Date: {FormatDate(value.Date)}</p>
                </span> -->
                <div class="content_wrapper">
                <span class="content" use:inview on:inview_change={(event) => {
                        console.log(event.detail.inView)
                        if (event.detail.inView == true) {
                            event.target.style.opacity = 1
                        } else if (event.detail.inView == false) {
                            event.target.style.opacity = 0
                        }
                    }}>
                    <h3>{value.Name}</h3>
                    {#each Object.entries(JSON.parse(value.Content)["blocks"]) as [key, value]}
                        {#if value.type == "header"}
                            <h4>{value.data.text}</h4>
                        {/if}
                        {#if value.type == "paragraph"}
                            <p>{value.data.text}</p>
                        {/if}
                        {#if value.type == "image"}
                        <figure>
                            <img class="images" width="300px" src='{value.data.file.url}' alt='{value.data.caption}'>
                            <figcaption>{value.data.caption}</figcaption>
                        </figure>
                        {/if}
                    {/each}
                </span>
                </div>
                <span class="info">
                    <h3>{value.Name}</h3>
                    <p>Author: {value.Author}</p>
                    <p>Date: {FormatDate(value.Date)}</p>
                </span>
            </div>
            <hr>
            {/each}
        {/if}	
        </span>
    </div>
<style>

.sidebar {
    padding: 2em;
}
.info {
    text-align: left;
    align-self: flex-start;
    border-radius: 5%;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.19);
    padding: 1em;
    background-color: rgba(255, 255, 200, 0.3);
    text-shadow: 1px 1px 1px gray;
    color: black;
}
img {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.images {
    display: flex;
    flex-direction: column;
}
hr {
    border-top: 1px solid gray;
    border-bottom: none;
}
.wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 2em;
    margin-top: 2em;
}
.superwrapper {
    display: flex;
}
.mega_wrapper {
    width: 75vw;
}
.content_wrapper {
    /* max-width: 75vw; */
    display: flex;
    margin-left: 1em;
    margin-right: 1em;
}
.content {
    background-color: rgba(255, 255, 200, 0.3);
    color: black;
    /* text-shadow: 1px 0px 1px white; */
    text-shadow: 1px 1px 1px gray;
    /* backdrop-filter: sepia(0.5); */
    transition: opacity 1.2s;
    text-align: left;
    margin-bottom: 2em;
    padding: 1em;
    /* max-width: 50%; */
    opacity: 0;
}

</style>