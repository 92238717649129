<script>

    let levels = {
        "10": "Extremely proficient",
        "9": "Very proficient",
        "8": "Quite proficient",
        "7": "Reasonably proficient",
        "6": "Beginner level",
        "5": "Beginner level",
        "4": "Beginner level",
        "3": "Beginner level",
        "2": "Beginner level",
        "1": "Beginner level",
        "0": "Beginner level"
    }

    let skill = {
        "Languages": {
            "PHP": "10",
            "HTML": "10",
            "JSON/XML/Yaml": "10",
            "Python": "9",
            "SQL": "9",
            "Javascript": "8",
            "CSS": "8",
            "C++": "7"
        },
        "Databases": {
            "Mysql": "10",
            "Mariadb": "10",
            "SQLite": "9",
            "MSSQL": "8",
            "InfluxDB": "7"
        },
        "Frameworks": {
            "JQuery": "9",
            "Flask/Bottle": "9",
            "Wordpress": "8",
            "Joomla": "8"
        },
        "Networking": {
            "Routing": "10",
            "Firewalls": "10",
            "VPN": "10",
            "VLAN": "10",
            "SSH": "10",
            "TCP/IP": "9"
        },
        "Operating Systems": {
            "Linux": "10",
            "Windows": "10",
            "OpenBSD": "10",
            "MacOS": "8"
        },
        "Technologies": {
            "NGINX/Apache": "10",
            "KVM/Xen": "10",
            "IIS": "8",
            "HyperV": "8",
            "VOIP/Broadsoft": "9",
            "API's": "9",
            "GIT": "7",
            "IOT/Embedded programming": "8"
        }
    }
    let skills = []
    skills["Languages"]
    skills["Languages"] = []
    skills["Databases"] = []
    skills["Frameworks"] = []
    skills["Networking"] = []
    skills["Operating systems"] = []
    skills["Technologies"] = []
    
    skills["Languages"].push("PHP")
    skills["Languages"].push("Python")
    skills["Languages"].push("Javascript")
    skills["Languages"].push("C++")
    skills["Languages"].push("SQL")
    skills["Languages"].push("CSS")
    skills["Languages"].push("HTML")
    skills["Languages"].push("JSON/XML/Yaml/..")
    
    skills["Operating systems"].push("Linux")
    skills["Operating systems"].push("Windows")
    skills["Operating systems"].push("MacOS")
    skills["Operating systems"].push("OpenBSD")

    skills["Databases"].push("MySQL")
    skills["Databases"].push("Mariadb")
    skills["Databases"].push("SQLite")
    skills["Databases"].push("MSSQL")
    skills["Databases"].push("InfluxDB")

    skills["Technologies"].push("NGINX / Apache / IIS")
    skills["Technologies"].push("VOIP / Broadsoft")
    skills["Technologies"].push("KVM / Xen / HyperV")
    skills["Technologies"].push("GIT / source control")
    skills["Technologies"].push("(REST) API's")
    skills["Technologies"].push("IOT / Embedded")

    skills["Frameworks"].push("Wordpress")
    skills["Frameworks"].push("Joomla")
    skills["Frameworks"].push("JQuery")
    skills["Frameworks"].push("Flask/Bottle")

    skills["Networking"].push("TCP/IP")
    skills["Networking"].push("Routing")
    skills["Networking"].push("Firewalls")
    skills["Networking"].push("VPNs")
    skills["Networking"].push("VLANs")
    skills["Networking"].push("SSH")

    function MouseOver(event) {
        if (event.target.style.color !== "gray") {
            event.target.style.color = "gray"
        } else {
            event.target.style.color = "black"
        }
    }

</script>

<div style="display: flex; flex-direction: column; justify-content:center; width: fit-content; margin: auto;">
    <div class="tables">  
        <table style="margin: 1em; margin-top: 0">
        {#each Object.entries(skill) as [key, value]}
        <span style="margin-right: 1em;">
            <tr>
                <th>{key}</th>
            </tr>
            {#each Object.entries(value) as [key, value]}
            <tr>
                <td title="{levels[value]}" on:mouseenter={MouseOver} on:mouseleave={MouseOver} on:focusout={MouseOver} on:focusin={MouseOver}>{key}</td>
            </tr>
            {/each}
        </span>
        {/each}
    </table>
    </div>
</div>

<style>
th {
    text-align: left;
    padding-bottom: 1em;
}
th, td {
    color: black;
}
td {
    text-align: left;
}
.tables {
    display: flex;
    flex-direction: row;
}
td:hover {
    cursor: help;
}

</style>